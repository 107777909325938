@import '/src/styles/utilities.scss';

.newFollowsModal__button {
  @include undo-default-button-styles();
  @include with-icon();
  @include text-button-effects();
}

.newFollowsModal__button_outlined {
  @include undo-default-button-styles();
  @include with-icon();
  @include primary-stroked-effects();

  min-width: 44px;
  min-height: 44px;
  padding: $button-padding-large;
  font-family: $font-primary;
  font-weight: $font-weight-bold;
  line-height: 1.5;
  text-transform: capitalize;
  border-radius: $border-radius-50;
}

.newFollowsModal__controls {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: $space-12;
  align-items: end;
  margin-top: $space-20;
}

.newFollowsModal__error {
  display: flex;
  flex-direction: column;
  gap: $space-20;
}
